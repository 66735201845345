import SkeletonMessagesList from '../../components/skeleton/messages-list';
import Arena from '../../components/arena';
import bem from '../../utils/bem';
import withI18n from '@ali/lzdmod-intl-utils';
import { IMState } from '@alife/lazada-im-core';
import { FormattedMessage } from '@alife/intl-components';
import { defineMessages } from '@alife/universal-intl';
import { When } from '@alife/simple-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlName } from '../../constants';
import Config from '../../utils/config';
import { getRegion } from '../../utils/host';
import translate, { getByPrefix } from '../../utils/locale';
import './index.scss';
import { IconMailBox } from './mailbox';
import OBMessageList, { enhanceCard } from './ob-message-list';
import requestMtop from '../../utils/mtop';


const messages = defineMessages({
  downloadAppLoggedIn: {
    id: 'im.message.downloadAppLoggedIn',
    defaultMessage: 'Get the latest merchant response in App Now',
  },
});

class MessageViewContent extends Component {
  static propTypes = {
    activeSessionId: PropTypes.string.isRequired,
    isMessageLoading: PropTypes.bool,
    imState: PropTypes.string.isRequired,
    setImagePreviewData: PropTypes.func.isRequired,
    emojiGroup: PropTypes.arrayOf({}).isRequired,
    loading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isMessageLoading: true,
  };

  state = {
    showOnboardWarning: false,
    warningMsg: ''
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.sellerId !== nextProps.sellerId) {
      this.fetchOnBoardWarning(nextProps.sellerId)
    }
  }

  fetchOnBoardWarning = (sellerId) => {
    requestMtop({
      api: 'mtop.lazada.merchant.onboard.im.warning',
      data: {
        sellerUserId: sellerId
      }
    }
    ).then(res => {
      if(res.data && res.data.reliable === 'false') {
        this.setState({
          showOnboardWarning: true,
          warningMsg: res.data.warningMsg
        })
      } else {
        this.setState({
          showOnboardWarning: false,
          warningMsg: ''
        })
      }
    })
  }

  constructor(props) {
    super(props);
    this.messageLocale = getByPrefix('im.messageList');
    this.messageLocale.read = translate('page.im.window.read');
    this.messageLocale.unread = translate('page.im.window.unread');
    this.messageLocale.follow = translate('page.im.window.follow');
    this.messageLocale.yesterday = translate('page.im.window.date.yesterday');
    enhanceCard({ setImagePreviewData: props.setImagePreviewData, emojiGroup: props.emojiGroup });
  }

  renderMessages() {
    const { activeSessionId, imState, isMessageLoading } = this.props;
    console.log('this.props', this.props)
    const downloadPageAddress = Config.DownloadAppAddress[getRegion().toUpperCase()];
    if (!activeSessionId && imState === IMState.success) {
      return (
        <div className="empty-box">
          <span className="empty-icon">
            <IconMailBox width={55} height={47} />
          </span>
          <div className="donwload-address">
            <a target="_blank" href={downloadPageAddress} rel="noreferrer noopener">
              {translate('downloadAppLoggedIn')}
            </a>
          </div>
          <FormattedMessage
            intlName={intlName}
            id="im.messageView.emptyNotice"
            defaultMessage="Once you start a new conversation, you’ll see it listed here."
            tagName="p"
          />
        </div>
      );
    }
    return (
      <div className="message-box">
        {isMessageLoading || !activeSessionId ? (
          <SkeletonMessagesList />
        ) : (
          <OBMessageList locale={this.messageLocale} />
        )}
      </div>
    );
  }

  render() {
    const { loading, isArenaRunning, arenaAnimator } = this.props;
    const { showOnboardWarning, warningMsg } = this.state
    const BEM = bem('message-view-content', {
      mod: {
        loading,
      },
    });
    return (
      <div className={BEM.block()} ref={this.setRef}>
        {
          showOnboardWarning &&
          <div className="on-board-warning">
            <img src="https://lzd-img-global.slatic.net/g/tps/imgextra/i2/O1CN01TmCcml1ESLIz7jK5Y_!!6000000000350-2-tps-60-60.png" alt=""/>
            <span>{warningMsg}</span></div>
        }
        {this.renderMessages()}
        <When condition={isArenaRunning}>
          <Arena arenaAnimator={arenaAnimator} />
        </When>
      </div>
    );
  }
}

export default withI18n(messages, intlName)(MessageViewContent);
