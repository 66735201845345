import goldlog from '@ali/lzdmod-goldlog';

const pagesNameMap = {
  order_details: 'orderdetails',
  shop: 'store',
  index: 'index',
  pdp: 'pdp',
};

function getPageName() {
  if (!window.dataLayer || !window.dataLayer[0]) {
    return;
  }
  const dataLayer = window.dataLayer[0];
  const pageType = dataLayer.pageType || dataLayer.pagetype;

  if (pagesNameMap[pageType]) {
    return pagesNameMap[pageType];
  }

  return 'others';
}

function trackCreateConversation(seller, page, options = {}) {
  const pagesParams = {
    pdp: 0,
    store: 0,
    order: 0,
  };

  if (page === 'sc_buyer_pdp') {
    pagesParams.pdp = 1;
  } else if (page === 'sc_buyer_order') {
    pagesParams.order = 1;
  } else if (page === 'sc_buyer_shop') {
    pagesParams.store = 1;
  }

  goldlog.click('/lazada.IM.im_entrypoint', null, {
    platform: 'desktop',
    pagename: getPageName(),
    seller,
    ...pagesParams,
    ...options,
  });
}

function trackExpMessageBox() {
  goldlog.show('/instantmessaging.lifecycle.pc.exp', null, {
    platform: 'desktop',
    pagename: getPageName(),
  });
}

function trackExpSessionList(params) {
  goldlog.show('/instantmessaging.session.list.pc.exp', null, {
    platform: 'desktop',
    pagename: getPageName(),
    ...params,
  });
}

function trackClkSessionList(params) {
  goldlog.click('/instantmessaging.session.list.pc.clk', null, {
    platform: 'desktop',
    pagename: getPageName(),
    ...params,
  });
}

function trackOpenMessagesBox(options = {}) {
  goldlog.click('/instantmessaging.lifecycle.pc.open', null, {
    platform: 'desktop',
    pagename: getPageName(),
    ...options,
  });
}

function trackError(options = {}) {
  goldlog.click('/lzdsellerapp.IM_PC.im-error', null, {
    platform: 'desktop',
    pagename: getPageName(),
    ...options,
  });
}

function traceMessageCardClick(options = {}) {
  goldlog.click('/lzdsellerapp.IM_PC.im-messagecard-clk', null, {
    platform: 'desktop',
    pagename: getPageName(),
    ...options,
  });
}

export default {
  trackOpenMessagesBox,
  trackCreateConversation,
  trackError,
  trackExpMessageBox,
  trackExpSessionList,
  trackClkSessionList,
  traceMessageCardClick
};
