import MessageList from '@alife/lazada-message-list';
import { inject } from 'mobx-react';
import { createSymbolReplace } from './symbol-replace';

const mapProps = ({ Store }) => ({
  isLoading: Store.isMoreMessageLoading,
  hasMore: true,
  loadMore: Store.getMoreMessage,
  messageList: Store.messageList,
  langKey: Store.sdkConfig.lang,
  onDelete: Store.selectRemoveMessage,
  onResend: Store.resendMessage,
  sessionId: Store.activeSessionId,
  symbolReplace: createSymbolReplace(Store.emojiGroup),
  messageWithdraw: Store.messageWithdraw,
  reUpdateInputMessage: Store.reUpdateInputMessage,
});

export { enhanceCard } from '../message-cards';
export default inject(mapProps)(MessageList);
