import { CardTypeCode, getCardMap } from '@alife/lazada-message-list';
import { inject } from 'mobx-react';
import React, { Component, createElement, type ComponentType } from 'react';
import { sendChoice } from '../../services/interaction';
import getGovHost, { getSiteMainDomain } from '../../utils/host';
import requestMtop from '../../utils/mtop';
import tracking from '../../utils/tracking';
import withI18n from '@ali/lzdmod-intl-utils';
import { defineMessages } from '@alife/universal-intl';
import { intlName } from '../../constants';
import './index.scss';

declare global {
  interface Window {
    _imSDKconfig?: { lang?: string; region?: string; };
  }
}

type CardComponent = ComponentType<any>;
type CardMapKey = number | 'default';
type CardMap = Map<CardMapKey, CardComponent>;

interface CardProps {
  templateData: any;
  onClick?: (data: any) => void;
  [key: string]: any;
}

interface CardActionProps extends CardProps {
  activeSession: any;
}

interface CardMapOptions {
  setImagePreviewData: (url: string, options: { hideSubmit: boolean }) => void;
  emojiGroup: Array<{
    list: Array<{
      key: string;
      [key: string]: any;
    }>;
  }>;
}

const VOUCHER_PRODUCT_CARD_TYPE = 200011;
const DEFAULT_LANG = 'en';
const DEFAULT_REGION = 'SG';

const safeJSONParse = <T extends object>(json: string, fallback: T): T => {
  try {
    return JSON.parse(json) as T;
  } catch (e) {
    return fallback;
  }
};

const openUrl = (url: string) => window.open(url);

const createCard = (
  CardComponent: CardComponent | undefined,
  enhanceProps?: (props: CardProps) => Partial<CardProps>
) => {
  if (!CardComponent) return null;

  return function EnhancedCard(props: CardProps) {
    const enhancedProps = enhanceProps ? { ...props, ...enhanceProps(props) } : props;
    return createElement(CardComponent, enhancedProps);
  }
};

function UpdateCardFollow(CardMap: CardMap, options: CardMapOptions) {
  const CardFollow = CardMap.get(CardTypeCode.CardFollow);
  if (!CardFollow) return;

  const CardFollowWithFunc = createCard(CardFollow, props => ({
    onClick: (content) => {
      requestMtop({
        api: content.actionUrl,
        data: {
          action_code: content.action,
          param_json: JSON.stringify(content),
        },
      });
    }
  }));

  if (CardFollowWithFunc) {
    CardMap.set(CardTypeCode.CardFollow, CardFollowWithFunc);
  }
}

function UpdateCardProduct(CardMap: CardMap, options: CardMapOptions) {
  const CardProduct = CardMap.get(CardTypeCode.CardProduct);
  if (!CardProduct) return;

  const CardProductWithFunc = createCard(CardProduct, 
    () => ({ onClick: templateData => openUrl(templateData.actionUrl) })
  );

  if (CardProductWithFunc) {
    CardMap.set(CardTypeCode.CardProduct, CardProductWithFunc);
  }
}

function UpdateCardVoucher(CardMap: CardMap, options: CardMapOptions) {
  const CardVoucher = CardMap.get(CardTypeCode.CardVoucher);
  if (!CardVoucher) return;

  const { lang = DEFAULT_LANG, region = DEFAULT_REGION } = window._imSDKconfig || {};

  const generateVoucherUrl = (content: { sellerId: string; voucherId: string }) => 
    `${getGovHost()}/shop/i/landing_page/voucher?sellerId=${content.sellerId}&voucherId=${
      content.voucherId
    }&wh_weex=true&scene=im&domain=store`;

  const CardVoucherWithFunc = createCard(CardVoucher,
    () => ({
      onClick: data => {
        tracking.traceMessageCardClick({ lang, region, targetUrl: data.pcBuyerUrl || generateVoucherUrl(data), targetType: 'voucher' });
        openUrl(data.pcBuyerUrl || generateVoucherUrl(data));
      }
    })
  );

  if (CardVoucherWithFunc) {
    CardMap.set(CardTypeCode.CardVoucher, CardVoucherWithFunc);
  }
}

function UpdateCardOrder(CardMap: CardMap, options: CardMapOptions) {
  const CardOrder = CardMap.get(CardTypeCode.CardOrder);
  if (!CardOrder) return;

  const getOrderUrl = (content: { title: string; orderId?: string }) => {
    const orderToken = content.title.match(/#(\d+)/);
    const orderId = content.orderId || (orderToken && orderToken[1] ? orderToken[1] : '');
    return `https://my.${getSiteMainDomain()}/customer/order/view/?tradeOrderId=${orderId}`;
  };

  const CardOrderWithFunc = createCard(CardOrder,
    () => ({ onClick: data => openUrl(data.pcBuyerUrl || getOrderUrl(data)) })
  );

  if (CardOrderWithFunc) {
    CardMap.set(CardTypeCode.CardOrder, CardOrderWithFunc);
  }
}

function UpdateCardImage(CardMap: CardMap, options: CardMapOptions) {
  const CardImage = CardMap.get(CardTypeCode.CardImage);
  if (!CardImage) return;

  const CardImageWithFunc = createCard(CardImage,
    () => ({ onClick: data => options.setImagePreviewData(data.imgUrl, { hideSubmit: true }) })
  );

  if (CardImageWithFunc) {
    CardMap.set(CardTypeCode.CardImage, CardImageWithFunc);
  }
}

function UpdateCardEmoji(CardMap: CardMap, options: CardMapOptions) {
  const CardEmoji = CardMap.get(CardTypeCode.CardEmoji);
  if (!CardEmoji) return;

  const emojiMap = options.emojiGroup.reduce((acc, group) => {
    group.list.forEach(item => {
      acc[item.key] = item;
    });
    return acc;
  }, {} as Record<string, any>);

  const CardEmojiWithFunc = createCard(CardEmoji, () => ({ emojiMap }));

  if (CardEmojiWithFunc) {
    CardMap.set(CardTypeCode.CardEmoji, CardEmojiWithFunc);
  }
}

function UpdateCardAction(CardMap: CardMap, options: CardMapOptions) {
  const CardAction = CardMap.get(CardTypeCode.CardAction);
  if (!CardAction) return;

  @inject(({ Store }) => ({
    activeSession: Store.activeSession,
  }))
  class CardActionWithFunc extends Component<CardActionProps> {
    render() {
      const { activeSession, ...rest } = this.props;
      const EnhancedCard = createCard(CardAction, () => ({
        onClick: (action: any) => sendChoice(action, activeSession)
      }));
      return EnhancedCard ? <EnhancedCard {...rest} /> : null;
    }
  }

  CardMap.set(CardTypeCode.CardAction, CardActionWithFunc);
}

function UpdateTextCard(CardMap: CardMap, options: CardMapOptions) {
  const CardText = CardMap.get(CardTypeCode.CardText);
  if (!CardText) return;

  const CardTextWithTranslate = createCard(CardText, () => ({ displayTranslate: true }));

  if (CardTextWithTranslate) {
    CardMap.set(CardTypeCode.CardText, CardTextWithTranslate);
  }
}

function UpdateUnpaidOrderCard(CardMap: CardMap, options: CardMapOptions) {
  const CardUnpaidOrder = CardMap.get(CardTypeCode.CardUnpaidOrder);
  if (!CardUnpaidOrder) return;

  const CardUnpaidOrderWithFunc = createCard(CardUnpaidOrder,
    () => ({ onClick: data => openUrl(data.toPayPCUrl4Buyer) })
  );

  if (CardUnpaidOrderWithFunc) {
    CardMap.set(CardTypeCode.CardUnpaidOrder, CardUnpaidOrderWithFunc);
  }
}

function UpdateVoucherProductCard(CardMap: CardMap, options: CardMapOptions) {
  const CardVoucher = CardMap.get(CardTypeCode.CardVoucher);
  if (!CardVoucher) return;

  interface ProductItem {
    id: string;
    icon: string;
    title: Record<string, string>;
    url: string;
    price1: string;
  }

  const messages = defineMessages({
    more_product: { id: 'im.messageCard.more.product', defaultMessage: 'View More Product' },
  });

  const VoucherProductCard = (props: CardProps) => {
    const { templateData, translate } = props;
    const { products, voucher, txt } = templateData;
    const { btnUrl, items: productsItems } = safeJSONParse<{ btnUrl: string; items: ProductItem[] }>(
      products || "{}", 
      { btnUrl: "", items: [] }
    );
    
    const propsToCardVoucher = {
      ...props,
      templateData: { ...safeJSONParse(voucher || "{}", {}) }
    };
    
    const { lang = DEFAULT_LANG, region = DEFAULT_REGION } = window._imSDKconfig || {};
    const _lang = lang.split('-')[0];

    const EnhancedCardVoucher = createCard(CardVoucher);

    const handleProductClick = (productUrl: ProductItem["url"]) => {
      if (!productUrl) return;
      tracking.traceMessageCardClick({ lang, region, targetUrl: productUrl, targetType: 'product' });
      openUrl(productUrl);
    };

    const handleFooterClick = () => {
      if (!btnUrl) return;
      tracking.traceMessageCardClick({ lang, region, targetUrl: btnUrl, targetType: 'more_product' });
      openUrl(btnUrl);
    };

    return (
      <div className='voucher-product-card'>
        <span className='voucher-product-card-title'>{txt}</span>
        {templateData.voucher && EnhancedCardVoucher && <EnhancedCardVoucher {...propsToCardVoucher} />}
        <div className='voucher-product-card-products'>
          {productsItems.map(({ id, icon, title, url, price1 }) => (
            <div key={id} className='voucher-product-card-product' onClick={() => handleProductClick(url)}>
              <img src={icon} className='voucher-product-card-product-icon' alt={title[_lang]} />
              <div className='voucher-product-card-product-info'>
                <span className='voucher-product-card-product-title'>{title[_lang]}</span>
                <span className='voucher-product-card-product-price'>{price1}</span>
              </div>
            </div>
          ))}
        </div>
        <div className='voucher-product-card-footer' onClick={handleFooterClick}>
          <span>{translate('more_product')}</span>
        </div>
      </div>
    );
  };

  const VoucherProductCardWithI18n = withI18n(messages, intlName)(VoucherProductCard)
  CardMap.set(VOUCHER_PRODUCT_CARD_TYPE, VoucherProductCardWithI18n);
}

function enhanceCard(options: CardMapOptions) {
  const CardMap = getCardMap();

  const updateQueue = [
    UpdateCardFollow,
    UpdateCardProduct,
    UpdateCardVoucher,
    UpdateCardOrder,
    UpdateCardImage,
    UpdateCardEmoji,
    UpdateCardAction,
    UpdateTextCard,
    UpdateUnpaidOrderCard,
    UpdateVoucherProductCard,
  ];

  updateQueue.forEach(fn => fn(CardMap, options));
}

export { enhanceCard };
